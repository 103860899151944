import {useEffect, useMemo, useState} from 'react';
import {
    IEMagReader,
    IItemRef,
    IManifestItem,
    INavChapter,
    IOpfPackage,
    IPackageNavigation
} from "@alchemisten/emag-core";

import {MagazineResolverType, ReaderFactory} from "../service/emag-factory";
import {IReaderError} from "../components/legacy-magazine-viewer";
import {IAPiEmagReaderEmbedProps} from "@alchemisten/emag-core/lib/reader/impl/access/api-emag-reader";

export interface MagazineHookProps {
    embed?: IAPiEmagReaderEmbedProps;
    magazineId: string;
    resolver?: MagazineResolverType;
    domain: string;
    initialContext?: string;
    initialPageKey?: string;
}

export interface MagazineData {
    magazine?: IOpfPackage;
    pageIndex?: IManifestItem[];
    pageMap?: Record<string, IItemRef>;
}

export interface PageInfo {
    currentPage?: IManifestItem;
    index: number;
}

export interface MagazineHookInstance extends MagazineData, PageInfo {
    isLoading: boolean;
    error?: IReaderError;
    reader?: IEMagReader;

    contextPageIndex?: IManifestItem[];
    context: string;
    availableContexts: string[];
    setContext: (context: string) => void;
    setCurrentPage: (index: number) => void;
    availableLanguages: string[];
    contextNavigation: IPackageNavigation;
}

export type MagazineHook = (props: MagazineHookProps) => MagazineHookInstance;

export const useMagazine: MagazineHook = ({
                                              embed,
                                              magazineId,
                                              resolver = MagazineResolverType.API,
                                              domain,
                                              initialContext,
                                              initialPageKey
                                          }) => {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<IReaderError | undefined>();
    const [reader, setReader] = useState<IEMagReader | undefined>();

    const [magazineData, setMagazineData] = useState<MagazineData>({});
    const [currentPage, setCurrentPageInfo] = useState<PageInfo>();

    const [context, setContext] = useState<string>(initialContext);

    useEffect(() => {
        console.log(`Loading magazine ${magazineId}`);
        // reset
        setLoading(true);
        setError(undefined);
        setReader(undefined);
        setMagazineData({});

        (async () => {
            const magReader = await ReaderFactory.ofType(resolver, domain, magazineId, embed);
            setReader(magReader);

            await magReader.loadMetaData();
            setLoading(false);

            console.log(`Parsing metadata`);
            const container = magReader.getContainer();

            if (!container) {
                setError({
                    message: 'Unable to parse Magazine'
                });
            } else {
                if (container.rootFiles.length > 0) {
                    const rootFile = container.rootFiles[0].content as IOpfPackage;

                    const pageResources = rootFile.spine.items
                        .map(ref => rootFile.manifest[ref.id]);

                    setMagazineData({
                        magazine: rootFile,
                        pageIndex: pageResources,
                        pageMap: pageResources.reduce((stack, page) => {
                            stack[page.id] = page;
                            return stack;
                        }, {})
                    })
                } else {
                    setError({
                        message: 'Magazine does not contain any root files'
                    });
                }
            }
            console.log(`Parsing data finished.`);
        })().then(() => { /* Done loading*/
        })
            .catch(err => {
                setLoading(false);
                setError({message: err.message})
            });
    }, [magazineId, embed?.magazineUrl, embed?.contentBaseUrl, embed?.useEventLoader]);

    const contextPageIndex = useMemo(() => {
        if (!magazineData || !magazineData.pageIndex) {
            return [];
        }

        return magazineData.pageIndex
            .filter(res => res.context && (res.context[context] && res.context[context] === 'include')); // TODO CONTEXT CORRECTEN!
    }, [magazineData, context]);

    const availableLanguages = useMemo<string[]>(() => {
        const languages: Record<string, number> = {};

        for (const page of contextPageIndex) {
            for (const langKey of page.availableLanguages) {
                if (!languages[langKey]) {
                    languages[langKey] = 1
                } else {
                    languages[langKey]++;
                }
            }
        }

        return Object.entries(languages)
            .filter(([_, count]) => count === contextPageIndex.length)
            .map(([k]) => k);
    }, [contextPageIndex]);

    const availableContexts = useMemo<string[]>(() => {
        if (!magazineData.pageIndex) {
            return [];
        }
        const allContexts = magazineData.pageIndex.reduce<Record<string, string>>((stack, page) => {
            return {
                ...stack,
                ...page.context
            };
        }, {});
        return Object.keys(allContexts);
    }, [magazineData]);

    const contextNavigation = useMemo<IPackageNavigation>(() => {
        const chapters: INavChapter[] = [];

        if (magazineData.magazine && contextPageIndex) {
            const availablePagesMap = contextPageIndex.reduce<Record<string, IManifestItem>>((stack, page) => {
                stack[page.id as string] = page;
                return stack;
            }, {});

            const navigationChapters = magazineData?.magazine?.spine?.nav?.chapters ?? [];

            for (const chapter of navigationChapters) {
                const chapterPages = chapter.pages.filter(page => !!availablePagesMap[page.id]);
                if (chapterPages.length > 0) {
                    chapters.push({
                        ...chapter,
                        pages: chapterPages
                    });
                }
            }
        }

        return {
            chapters
        }
    }, [magazineData, contextPageIndex]);

    useEffect(() => {
        if (initialPageKey && contextPageIndex.length > 0) {
            const startPageIndex = contextPageIndex.findIndex(p => p.id == initialPageKey);
            if (startPageIndex !== -1) {
                setCurrentPage(startPageIndex);
            }
        }
    }, [magazineData, contextPageIndex, initialPageKey]);

    const setCurrentPage = (index) => {
        setCurrentPageInfo({
            index,
            currentPage: contextPageIndex[index]
        });
    }

    return {
        ...magazineData,
        ...currentPage,
        error,
        reader,
        contextPageIndex,
        isLoading,
        context,
        setContext,
        setCurrentPage,
        availableLanguages,
        availableContexts,
        contextNavigation
    }
}