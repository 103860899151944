import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import { IEMagReader, IOpfPackage }               from '@alchemisten/emag-core';
import { StylesMap }                              from '../types/style';

import { PropagateLoader  } from 'react-spinners';

import animejs from 'animejs';

export interface IMagletPageProps {
    language?: string | null;
    reader: IEMagReader;
    package: IOpfPackage;
    id: string | number;
    width: number;
    height: number;
    extraParams?: Record<string, string>;
}

const MagletPage: FC<IMagletPageProps> = ( { reader, id, package: opfPackage, width, height , language, extraParams} ) => {

    const [ withOverlay, setWithOverlay ] = useState<boolean>(false);
    const [ lang, setLanguage ] = useState<string|null|undefined>(language);

    const overlayRef = useRef<HTMLDivElement>(null);
    const frameRef = useRef<HTMLIFrameElement>(null);

    const resource = opfPackage.manifest[id];

    const fadeBlend = async () => {
        if(overlayRef.current) {
            const instance = animejs({
                targets: overlayRef.current,
                opacity: 0,
                duration: 300,
                easing: 'easeInOutCubic'
            });

            await instance.finished;
            setWithOverlay(false);
        }

    };

    useEffect(() => {
        setWithOverlay(true);
        if(overlayRef.current) {
            overlayRef.current.style.opacity = '1';
        }

        setTimeout(fadeBlend, 350);
    }, [id]);

    useEffect(() => {
        setLanguage(language);
        if(frameRef.current) {
            frameRef.current.contentWindow.postMessage(JSON.stringify({
                type: 'set-language',
                lang: language
            }), new URL(resource.href).origin);
        }
    }, [language]);

    const pageSrc = useMemo(() => {
        const u = new URL(resource.href);
        u.searchParams.set('lang', lang);

        if(extraParams) {
            Object.entries(extraParams).forEach(([key, value]) => u.searchParams.set(key, value));
        }

        return u.toString();
    }, [resource.href, extraParams]);

    if(!resource) {
        return <div>Invalid Resource ID {id}</div>;
    }

    return (
        <div style={styles.wrapper}>
            <div style={styles.content}>
                <iframe onLoad={() => fadeBlend() } ref={frameRef} style={styles.frame} src={pageSrc} width={`${width}px`} height={`${height}px`}/>
                { withOverlay &&
                    <div ref={overlayRef} style={{
                        ...styles.overlay,
                        width,
                        height
                    }} >
                        <div
                            style={styles.loading}
                            >
                            <PropagateLoader color={'#0F4279'} size={40}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
};

const styles: StylesMap = {
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        position: 'relative'
    },
    content: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0
    },
    frame: {
        border: 'none',
    },
    overlay: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'white',
    },
    loading: {
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center'
    }
};

export default MagletPage;