import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import MagazineWrapper from './magazine-wrapper';

import { initializeIcons } from '@uifabric/icons';
import { LanguageProvider } from './providers/translation-provider';
import {MagazineRouter} from "./views/magazine-router";

initializeIcons();

const urlLocale = new URLSearchParams(window.location.search).get('lang') ?? undefined;

ReactDOM.render(
    <LanguageProvider initialLanguage={urlLocale ?? localStorage.getItem('@language')}>
        <Router>
            <Switch>
                <Route path={ '/magazines/:magazineId' }>
                    <MagazineRouter/>
                </Route>
                <Route path={ '/magazines-legacy/:magazineId' }>
                    <MagazineWrapper/>
                </Route>
                <Route path={'*'}>
                    <MagazineRouter/>
                </Route>
            </Switch>
        </Router>
    </LanguageProvider>,
    document.getElementById( 'root' )
);
