import React, {FC, useMemo, useState} from 'react';
import { StylesMap } from '../types/style';
import { Icon } from '@fluentui/react/lib/Icon';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { Slider } from 'office-ui-fabric-react/lib/Slider';
import {FormattedMessage, useIntl} from "react-intl";
import {useTranslations} from "../providers/translation-provider";
import {IPackageNavigation} from "@alchemisten/emag-core";
import {MOCK_MAGS} from "../mock/magazines";

const styles: StylesMap = {
    flexFull: {
        display: 'flex',
        width: '100%'
    },
    softShadow: {
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 20px 0px'
    },
    link: {
        cursor: 'pointer'
    },
    wrapper: {
        position: 'relative'
    },
    nav: {
        position: 'absolute',
        color: 'rgba(0,0,0,0.4)',
        fontSize: '1em',
        bottom: 20,
        right: 20
    },
    tocWrapper: {
        zIndex: 1000,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'row'
    },
    tocContent: {
        position: 'relative',
        padding: '35px',
        display: 'flex',
        minWidth: '350px',
        backgroundColor: 'white',
        flexDirection: 'column'
    },
    navigationWrapper: {
        position: 'absolute',
        zIndex: 100,
        left: 0,
        bottom: 0,
        right: 0
    },
    navigationStack: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    navigationOverlay: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0, 0.25)',
        cursor: 'pointer'
    },
    navigationContent: {
        position: 'relative',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        padding: '10px 25px'
    },
    loading: {
        margin: 20,
        fontSize: '2em'
    }
};

export interface ViewerOverlayProps {
    withMenu?: boolean;
    totalPages: number;
    currentPage: number;
    currentPageKey: string;
    changePage: (index: number) => void;
    changePageByKey: (key: string | number) => void;
    sliderTitle?: Record<string, string>;
    menuTitle?: Record<string, string>;
    availableLanguages: string[];
    currentContext: string;
    availableContexts: string[];
    changeContext: (context: string) => void;
    navigation: IPackageNavigation;
}

export const ViewerOverlay: FC<ViewerOverlayProps> = (props) => {
    const { children, withMenu = true, totalPages, currentPage, changePage, sliderTitle, menuTitle, currentContext, changeContext, navigation, currentPageKey, changePageByKey } = props;

    const [ isMenuOpen, setMenuOpen ] = useState<boolean>( false );
    const [ tocOpen, setTocOpen ] = useState<boolean>( false );

    const intl = useIntl();

    const { currentLanguage, setLanguage } = useTranslations();

    const handleContextChange = ( _event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption ) => {
        if ( option ) {
            changeContext(option.key as string);
        }
    }

    const handleNavigationPreview = ( index: number ) => {
        changePage(index);
    }

    const handleNavigationChange = ( _event: MouseEvent, index: number ) => {
        changePage(index);
    }

    const handleCloseMenu = () => {
        handleMenuToggle( false );
    }

    const handleMenuToggle = async ( open: boolean ) => {
        if ( !open ) {
            await setTocOpen( false );
        }
        setMenuOpen( open );
    }

    const handleTocOpen = () => {
        handleTocToggle( true );
    }

    const handleTocClose = () => {
        handleTocToggle( false );
    }

    const handleChangeLanguage = (_event: any, option: IDropdownOption) => {
        setLanguage(option.key as string);
    }

    const handleTocToggle = async ( open: boolean ) => {
        setTocOpen( open );
    }

    const handleSetPageFromNavigation = (pageKey?: string | number) => () => {
        if(pageKey) {
            changePageByKey(pageKey);
        }
    }

    const findTranslation = (stack: Record<string, string>, languageKey: string) => {
        if(!stack) {
            return '';
        }
        if(stack[languageKey]) {
            return stack[languageKey];
        }

        const keys = Object.keys(stack);
        if(keys.length > 0) {
            return stack[keys[0]]
        }
        return '';
    }

    const translated = useMemo(() => {

        return {
            sliderTitle: findTranslation(sliderTitle, currentLanguage),
            menuTitle: findTranslation(menuTitle, currentLanguage)
        }
    }, [sliderTitle, menuTitle, currentLanguage]);

    const availableLanguages = useMemo<IDropdownOption[]>(() => {
        return props.availableLanguages.map(languageKey => {
            return {
                key: languageKey,
                text: intl.formatMessage({id: `__lang.${languageKey}`})
            }
        });
    }, [props.availableLanguages, currentLanguage]);

    const availableContexts = useMemo<IDropdownOption[]>(() => {
        return props.availableContexts.map(contextKey => {
            return {
                key: contextKey,
                text: intl.formatMessage({id: `context.${contextKey}`})
            }
        });
    }, [props.availableContexts]);

    const firstNavigationPage = useMemo(() => {
        if(navigation.chapters.length > 0) {
            const firstChapter = navigation.chapters[0];
            if(firstChapter.pages.length > 0) {
                return firstChapter.pages[0].id;
            }
        }

        return '';
    }, [navigation]);

    //const { x: width, y: height } = windowSize;

    const topMenuHeight = 42;
    const paddingTop = 5;
    const topMenuHeightPx = `${ topMenuHeight }px`;

    return (
        <div style={ styles.wrapper } onTouchMoveCapture={ e => {
            e.stopPropagation();
            e.preventDefault();
        } }>
            { withMenu &&
            <div style={ { height: `${ topMenuHeight - paddingTop * 2 }px`, padding: `${ paddingTop }px 25px` } }>
                <div style={ { ...styles.flexFull } }>
                    <div style={ { flex: 1 } }>
                        <strong>
                            {translated.menuTitle}
                        </strong>
                    </div>
                    <div onClick={ () => handleMenuToggle( !isMenuOpen ) }>
                        <Icon iconName="Breadcrumb" style={ { fontSize: 38 } }/>
                    </div>
                </div>

            </div> }
            {
                !withMenu &&
                    <div style={{position: 'fixed', top: 20, right: 20, zIndex: 1000}}>
                        <div onClick={ () => handleMenuToggle( !isMenuOpen ) }>
                            <Icon iconName="Breadcrumb" style={ { fontSize: 38 } }/>
                        </div>
                    </div>
            }
            <div style={ {
                ...styles.navigationWrapper,
                top: withMenu ? topMenuHeightPx : 0,
                pointerEvents: isMenuOpen ? 'all' : 'none'
            } }>
                {
                    isMenuOpen && <div style={ {
                        ...styles.navigationStack,
                        height: withMenu ? `calc( 100vh - ${ topMenuHeightPx } )` : '100vh'
                    } }>
                        {
                            tocOpen && <div style={ styles.tocWrapper }>
                                <div style={ { ...styles.tocContent, ...styles.softShadow } }>
                                    <div style={ { display: 'flex', flexDirection: 'row' } }>
                                        <div style={ { flex: 1 } }>
                                            <Label style={ { fontSize: 24 } }><FormattedMessage id={'menu.navigation'}/></Label>
                                        </div>
                                        <div onClick={ handleTocClose } style={ { cursor: 'pointer' } }>
                                            <Icon iconName={ 'Cancel' }/>
                                        </div>
                                    </div>
                                    <div style={ { marginTop: '20px', overflowY: "auto" } }>
                                        <div>
                                            <Label
                                                style={ {
                                                    ...styles.link,
                                                    color: currentPageKey === firstNavigationPage ? 'blue' : 'black'
                                                } }
                                                onClick={ handleSetPageFromNavigation(firstNavigationPage) }
                                            >{ translated.menuTitle }</Label>
                                            <ul>
                                                {
                                                    navigation.chapters.map( chapter => {
                                                        return (
                                                            <li key={ chapter.id } style={ styles.link }>
                                                                <Label
                                                                    style={ {
                                                                        ...styles.link,
                                                                        padding: '2px',
                                                                        color: 'black'
                                                                    } }>{ findTranslation(chapter.i18n, currentLanguage) }</Label>
                                                                <ul>
                                                                    {
                                                                        chapter.pages.map( page => {
                                                                            return (
                                                                                <li key={ page.id }
                                                                                    style={ styles.link }>
                                                                                    <Label
                                                                                        onClick={ handleSetPageFromNavigation(page.id) }
                                                                                        style={ {
                                                                                            ...styles.link,
                                                                                            padding: '2px',
                                                                                            color: currentPageKey == page.id ? 'blue' : 'black'
                                                                                        } }>{ findTranslation(page.i18n, currentLanguage) }</Label>
                                                                                </li>
                                                                            )
                                                                        } )
                                                                    }
                                                                </ul>
                                                            </li>
                                                        )
                                                    } )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div style={ { display: 'flex', ...styles.navigationOverlay } }
                                     onClick={ handleTocClose }>
                                </div>
                            </div>
                        }
                        <div style={ { ...styles.softShadow } }>
                            <div style={ { ...styles.navigationContent } }>
                                <div style={ styles.flexFull }>
                                    <div style={ {
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        alignContent: 'baseline',
                                        cursor: 'pointer'
                                    } }
                                         onClick={ handleTocOpen }>
                                        <Icon iconName={ 'DOM' } style={ { fontSize: 22 } }/>
                                        <Label style={ { cursor: 'pointer' } }><FormattedMessage id={'menu.toc'}/></Label>
                                    </div>

                                    <div>
                                        <Dropdown
                                            label={ intl.formatMessage({id: 'menu.context'}) }
                                            placeHolder={ intl.formatMessage({id: 'menu.select-context'}) }
                                            selectedKey={ currentContext }
                                            options={ availableContexts }
                                            onChange={ handleContextChange }
                                        />
                                    </div>
                                    <div style={ { borderRight: 'rgba(0,0,0, 0.25) solid 1px', margin: '0px 15px' } }>

                                    </div>
                                    <div style={{marginRight: '45px'}}>
                                        <Dropdown
                                            label={ intl.formatMessage({id: 'app.language'}) }
                                            selectedKey={ currentLanguage }
                                            options={ availableLanguages }
                                            onChange={ handleChangeLanguage }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={ { ...styles.navigationOverlay } } onClick={ handleCloseMenu }>
                        </div>
                        <div style={ { ...styles.softShadow } }>
                            <div style={ { ...styles.navigationContent } }>
                                <div style={ styles.flexFull }>
                                    <div style={ { flex: 1 } }>
                                        <Slider
                                            label={ translated.sliderTitle }
                                            min={ 1 }
                                            max={ totalPages }
                                            step={ 1 }
                                            value={ currentPage }
                                            showValue
                                            snapToStep
                                            onChange={ handleNavigationPreview }
                                            onChanged={ handleNavigationChange }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
            <div>
                { children }
            </div>
        </div>
    )
}