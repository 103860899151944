import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import { IntlProvider }                                          from 'react-intl';

// language files
import de                from '../assets/i18n/de-DE.json';
import en                from '../assets/i18n/en-US.json';
import fr                from '../assets/i18n/fr-FR.json';
import es                from '../assets/i18n/es-ES.json';
import pl                from '../assets/i18n/pl-PL.json';
import cz                from '../assets/i18n/cs-CZ.json';
import ru                from '../assets/i18n/ru-RU.json';
import it                from '../assets/i18n/it-IT.json';
import cn                from '../assets/i18n/zh-CHS.json';

import { flattenObject }    from "../utils";
import _                    from "lodash";

export const DEFAULT_LANGUAGE = (navigator.language || (navigator as any).userLanguage).replace(/^([\w]+)(-.*)/ig, '$1');

export interface TranslationsType<T = string> {
    [key: string]: {
        [key: string]: T
    }
}

export interface TranslationsContextType {
    translations: TranslationsType;
    currentLanguage: string;
    setLanguage: (key: string) => void;
    addTranslations: (translations: TranslationsType<any>) => void;
}

export const TranslationsContext = createContext<TranslationsContextType>({
    translations: {},
    currentLanguage: DEFAULT_LANGUAGE,
    setLanguage: () => {},
    addTranslations: () => {},
});

export const useCreateTranslations = (initialLanguage: string, initialTranslations: TranslationsType) => {
    const [{ currentLanguage, translations }, setTranslations] = useState( {
        currentLanguage: initialLanguage,
        translations: initialTranslations
    });

    const addTranslations = (newTranslations: TranslationsType<any>) => {
        const flattenedLang = Object.keys(newTranslations).reduce<TranslationsType>((stack, lang: string) => {
            stack[lang] = flattenObject(newTranslations[lang]);
            return stack;
        }, {});

        setTranslations({
            currentLanguage,
            translations: _.merge(translations, flattenedLang)
        });
    };

    const setLanguage = (lang: string) => {
        localStorage.setItem('@language', lang);
        setTranslations({
            currentLanguage: lang,
            translations
        });
    }

    useEffect(() => {
        addTranslations(initialTranslations);
    }, [initialTranslations]);

    return {
        translations,
        currentLanguage,
        setLanguage,
        addTranslations
    }
}

export const useTranslations = (): TranslationsContextType => {
    return useContext(TranslationsContext);
}

const defaultTranslations: TranslationsType = {
    de: flattenObject(de),
    en: flattenObject(en),
    fr: flattenObject(fr),
    es: flattenObject(es),
    pl: flattenObject(pl),
    cz: flattenObject(cz),
    ru: flattenObject(ru),
    it: flattenObject(it),
    cn: flattenObject(cn),
};

type LanguageProviderProps = {
    initialLanguage?: string;
    initialTranslations?: TranslationsType;
};

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ initialLanguage, initialTranslations, children }) => {
    const defaultLocale = initialLanguage || (defaultTranslations[DEFAULT_LANGUAGE] ? DEFAULT_LANGUAGE : 'en');
    const translations = useCreateTranslations(
        defaultLocale,
        _.merge(defaultTranslations, initialTranslations));

    const { setLanguage } = useTranslations();

    useEffect(() => {
        if(initialLanguage) {
            setLanguage(defaultLocale);
        }
    }, [defaultLocale]);

    const { currentLanguage, translations: currentTranslations } = translations;

    return (
        <TranslationsContext.Provider value={translations}>
            <IntlProvider
                defaultLocale={'en'}
                locale={currentLanguage}
                messages={currentTranslations[currentLanguage]}
                onError={() => {}}
            >
                {children}
            </IntlProvider>
        </TranslationsContext.Provider>
    );
};