"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpfPackageKeys = void 0;
var OpfPackageKeys;
(function (OpfPackageKeys) {
    OpfPackageKeys["VERSION"] = "version";
    OpfPackageKeys["UNIQUE_ID"] = "unique-identifier";
    OpfPackageKeys["LANG"] = "xml:lang";
    OpfPackageKeys["PREFIX"] = "prefix";
    OpfPackageKeys["DC_ID"] = "dc:identifier";
    OpfPackageKeys["DC_TITLE"] = "dc:title";
    OpfPackageKeys["DC_CREATOR"] = "dc:creator";
    OpfPackageKeys["DC_LANGUAGE"] = "dc:language";
    OpfPackageKeys["DC_DATE"] = "dc:date";
})(OpfPackageKeys = exports.OpfPackageKeys || (exports.OpfPackageKeys = {}));
