import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {ViewerOverlay} from '../components/viewer-overlay';
import {useWindowDimensions} from '../hooks/window';

import SwiperCore, {Controller, Virtual} from 'swiper';
import {Swiper, SwiperSlide} from "swiper/react";
import {useMagazine} from "../hooks/magazines";
import {GridLoader} from "react-spinners";
import {FormattedMessage} from "react-intl";
import {StylesMap} from "../types/style";
import MagletPage from "../components/maglet-page";
import {useTranslations} from "../providers/translation-provider";
import {IAPiEmagReaderEmbedProps} from "@alchemisten/emag-core/lib/reader/impl/access/api-emag-reader";

SwiperCore.use([Controller, Virtual]);

export interface MagazineOutletProps {
    embedded?: IAPiEmagReaderEmbedProps;
    magazineId: string;
    domain: string;
    language: string;
    withMenu: boolean;
    context?: string;
    pageKey?: string;
    onContextChange?: (context: string) => void;
    onPageChange?: (pageKey: string | number) => void;
    extraParams?: Record<string, string>;
}

export const MagazineOutlet: FC<MagazineOutletProps> = ({
                                                            embedded,
                                                            withMenu,
                                                            magazineId,
                                                            domain,
                                                            pageKey: initialPageKey,
                                                            context: initialContext,
                                                            language: initialLanguage,
                                                            onContextChange,
                                                            onPageChange,
                                                            extraParams
                                                        }) => {

    const magInstance = useMagazine({
        embed: embedded,
        magazineId,
        domain,
        initialPageKey,
        initialContext,
    });
    const {x, y} = useWindowDimensions();
    const {currentLanguage, setLanguage} = useTranslations();

    const [swiper, setSwiper] = useState<SwiperCore>();

    const handleNextPage = () => {
        if (swiper) {
            magInstance.setCurrentPage(swiper.activeIndex + 1);
        }
    }

    const handlePrevPage = () => {
        if (swiper) {
            magInstance.setCurrentPage(swiper.activeIndex - 1);
        }
    }

    const handleSliderPageChange = (index: number) => {
        magInstance.setCurrentPage(index - 1);
    }

    const handleChangePageByKey = (key: string | number) => {
        if (magInstance.contextPageIndex) {
            const pageIndex = magInstance.contextPageIndex.findIndex(page => page.id === key);
            if (pageIndex !== -1) {
                magInstance.setCurrentPage(pageIndex);
            }
        }
    }

    useEffect(() => {
        if (swiper && magInstance.index >= 0) {
            swiper.slideTo(magInstance.index);
        }
    }, [swiper, magInstance.index]);

    useEffect(() => {
        if (onPageChange && magInstance.currentPage) {
            onPageChange(magInstance.currentPage.id);
        }
    }, [magInstance.currentPage]);

    useEffect(() => {
        if (onContextChange && magInstance.context) {
            onContextChange(magInstance.context);
        }
    }, [magInstance.context]);

    const pageState = useMemo(() => {
        let currentPage = 1;
        let isFirstPage = true;
        let isLastPage = false;
        if (magInstance.currentPage || magInstance.index) {
            currentPage = (magInstance.index) + 1;
            isFirstPage = currentPage === 1;
            isLastPage = currentPage >= (magInstance.contextPageIndex?.length ?? 1);
        }

        return {
            currentPage,
            isLastPage,
            isFirstPage
        }
    }, [magInstance.currentPage, magInstance.contextPageIndex, magInstance.index]);

    useEffect(() => {
        if (magInstance.availableLanguages.length > 0) {
            if (!magInstance.availableLanguages.includes(currentLanguage)) {
                const storedLanguage = localStorage.getItem('@language');
                if (magInstance.availableLanguages.includes(storedLanguage)) {
                    setLanguage(storedLanguage);
                } else {
                    setLanguage(magInstance.availableLanguages[0]);
                }
            }
        }
    }, [magInstance.availableLanguages, currentLanguage]);

    if (magInstance.isLoading) {
        return (
            <div style={styles.loading}>
                <GridLoader color={'#0F4279'}/>
                <h3><FormattedMessage id={'app.loading'}/></h3>
            </div>
        )
    }

    const contentHeight = withMenu ? y - 42 : y;

    return (
        <ViewerOverlay
            withMenu={withMenu}
            totalPages={magInstance.contextPageIndex.length}
            currentPage={pageState.currentPage}
            currentPageKey={magInstance.currentPage?.id as string ?? ''}
            changePage={handleSliderPageChange}
            changePageByKey={handleChangePageByKey}
            availableLanguages={magInstance.availableLanguages}
            availableContexts={magInstance.availableContexts}
            currentContext={magInstance.context}
            changeContext={magInstance.setContext}
            sliderTitle={magInstance.currentPage?.i18n ?? {}}
            menuTitle={magInstance.magazine?.i18n ?? {}}
            navigation={magInstance.contextNavigation}
        >
            <Swiper
                onSwiper={setSwiper}
            >
                {
                    magInstance.contextPageIndex.map((page, index) => {
                        return <SwiperSlide
                            key={page.id}
                            style={{
                                height: contentHeight,
                                pointerEvents: 'none'
                            }}
                        >
                            {({isActive, isPrev, isNext}) => {
                                const shouldRender = isActive || isPrev || isNext;
                                if (!shouldRender) {
                                    return;
                                }

                                return (
                                    <div style={styles.pageWrapper}>
                                        <div style={styles.pageContent}>
                                            <MagletPage
                                                id={page.id}
                                                reader={magInstance.reader}
                                                package={magInstance.magazine}
                                                width={x}
                                                height={contentHeight}
                                                language={currentLanguage}
                                                extraParams={extraParams}
                                            />
                                        </div>
                                        <div style={styles.swipeAreaLeft}/>
                                        <div style={styles.swipePassThrough}/>
                                        <div style={styles.swipeAreaRight}/>
                                    </div>
                                )
                            }}
                        </SwiperSlide>
                    })
                }
                <div style={{...styles.pageChange, ...((pageState.isLastPage) && styles.pageChangeDisabled)}}
                     onClick={handleNextPage} className={'swiper-button-next'}/>
                <div style={{...styles.pageChange, ...((pageState.isFirstPage) && styles.pageChangeDisabled)}}
                     onClick={handlePrevPage} className={'swiper-button-prev'}/>
            </Swiper>
        </ViewerOverlay>
    )
}

const styles: StylesMap = {
    loading: {
        display: 'flex',
        flexGrow: 1,
        height: '100vh',
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'column'
    },
    pageWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
    },
    pageContent: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        pointerEvents: 'all'
    },
    pageChange: {
        margin: '0 20px'
    },
    pageChangeDisabled: {
        cursor: 'default',
        opacity: 0.1,
    },
    swipeAreaLeft: {
        width: '120px',
        height: '100vh',
        pointerEvents: 'all'
    },
    swipeAreaRight: {
        marginRight: '15px',
        width: '105px',
        height: '100vh',
        pointerEvents: 'all'
    },
    swipePassThrough: {
        flex: 1,
        pointerEvents: 'none'
    }
};