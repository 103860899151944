export const MOCK_MAGS = {
    149: {
        'id': '1631',
        'lang': {
            'de': 'Utz Gruppe',
            'en': 'Utz Group',
            'fr': 'Le Groupe Utz',
            'pl': 'Grupa Utz',
            'cz': 'Skupina Utz ',
            'es': 'Grupo Utz'
        },
        'children': [
            {
                'id': '395',
                'lang': {
                    'de': 'Utz Gruppe',
                    'en': 'Utz Group',
                    'fr': 'Le Groupe Utz ',
                    'pl': 'Grupa Utz',
                    'cz': 'Skupina Utz ',
                    'es': 'Grupo Utz'
                },
                'children': [
                    {
                        'id': '351',
                        'lang': {
                            'de': 'Weltweit',
                            'en': 'worldwide',
                            'fr': 'À travers le monde',
                            'pl': 'na świecie',
                            'cz': 've světě ',
                            'es': 'en todo el mundo'
                        },
                        'children': []
                    },
                    {
                        'id': '615',
                        'lang': {
                            'de': 'Zahlen 2019',
                            'en': 'Key Figures 2019',
                            'fr': 'Les chiffres 2019',
                            'pl': 'W liczbach (2019)',
                            'cz': 'čísla (2019) ',
                            'es': 'Las Métricas Clave 2019'
                        },
                        'children': []
                    },
                    {
                        'id': '687',
                        'lang': {
                            'de': 'Organisation',
                            'en': 'Organization',
                            'fr': 'L’organisation',
                            'pl': 'Organizacja',
                            'cz': 'organizace ',
                            'es': 'organización'
                        },
                        'children': []
                    },
                    {
                        'id': '156',
                        'lang': {
                            'de': 'Markenwerte',
                            'en': 'The Utz Group Brand Values',
                            'fr': 'Les valeurs du Groupe Utz ',
                            'pl': 'wartość marki',
                            'cz': 'hodnoty značky ',
                            'es': 'Los valores de la marca'
                        },
                        'children': []
                    },
                    {
                        'id': '606',
                        'lang': {
                            'de': 'Nachhaltigkeit',
                            'en': 'Sustainability',
                            'fr': 'Durabilité',
                            'pl': 'Trwałość',
                            'cz': 'Udržitelnost',
                            'es': 'Sustentabilidad'
                        },
                        'children': []
                    }
                ]
            },
            {
                'id': '601',
                'lang': {
                    'de': 'Produkte & Lösungen',
                    'en': 'products and solutions',
                    'fr': 'Produits et solutions',
                    'pl': 'Produkty i rozwiązania',
                    'cz': 'Produkty a řešení',
                    'es': 'Productos y soluciones'
                },
                'children': [
                    {
                        'id': '766',
                        'lang': {
                            'de': 'Branchenspezifische Lösungen',
                            'en': 'Solutions industry-specific',
                            'fr': 'Les solutions spécifiques par types d’industries  ',
                            'pl': 'Rozwiązania uwzględniające specyfikę branży',
                            'cz': 'Průmyslová řešení',
                            'es': 'Soluciones específicas de la industria '
                        },
                        'children': []
                    },
                    {
                        'id': '738',
                        'lang': {
                            'de': 'Produktspezifische Lösungen',
                            'en': 'Product-specific solutions',
                            'fr': 'Solutions de produits spécifiques ',
                            'pl': 'Rozwiązania uwzględniające specyfikę produktu',
                            'cz': 'Řešení zohledňující specifikum produktu',
                            'es': 'Productos para soluciones especificas'
                        },
                        'children': []
                    },
                    {
                        'id': '345',
                        'lang': {
                            'de': 'Kundenindividuelle Lösungen',
                            'en': 'Customized solutions',
                            'fr': 'Les solutions sur-mesure',
                            'pl': 'Rozwiązania indywidualne dla Klientów',
                            'cz': 'Individuální řešení pro zákazníky',
                            'es': 'Soluciones personalizadas '
                        },
                        'children': []
                    }
                ]
            },
            {
                'id': '1712',
                'lang': {
                    'de': 'Insides',
                    'en': 'Internal',
                    'fr': 'Insides',
                    'pl': 'Insides',
                    'cz': 'Insides',
                    'es': 'Insides'
                },
                'children': [
                    {
                        'id': '409',
                        'lang': {
                            'de': 'Spritzgiessen',
                            'en': 'injection molding',
                            'fr': 'Le moulage par injection',
                            'pl': 'Proces formowania wtryskowego',
                            'cz': 'vstřikovací tvarování ',
                            'es': 'Moldeo por inyección'
                        },
                        'children': []
                    },
                    {
                        'id': '339',
                        'lang': {
                            'de': 'Thermoformen',
                            'en': 'thermoforming',
                            'fr': 'Le thermoformage',
                            'pl': 'Proces formowania próżniowego ',
                            'cz': 'vakuové tvarování',
                            'es': 'Termoformado'
                        },
                        'children': []
                    },
                    {
                        'id': '407',
                        'lang': {
                            'de': 'Extrusion',
                            'en': 'extrusion',
                            'fr': 'L’extrusion',
                            'pl': 'Proces ekstruzji',
                            'cz': 'extruze',
                            'es': 'Extrusión'
                        },
                        'children': []
                    },
                    {
                        'id': '273',
                        'lang': {
                            'de': 'Qualitätsmanagement',
                            'en': 'quality management',
                            'fr': 'Gestion de la qualité',
                            'pl': 'Zarządzanie jakością',
                            'cz': 'řízení kvality',
                            'es': 'Gestión de la calidad '
                        },
                        'children': []
                    }
                ]
            }
        ]
    }
}
