import React, { FC, useEffect, useState } from 'react';
import { StylesMap } from '../types/style';

import { Text } from 'office-ui-fabric-react/lib/Text';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton } from 'office-ui-fabric-react';

const UTZ_LOGO = 'https://www.utzgroup.com/typo3conf/ext/tsbasetemplate/Resources/Public/images/utz.svg';

export interface SimpleAuthenticationProps {
    expectedToken: string;
    onAuthorized: () => void;
}

const SimpleAuthentication: FC<SimpleAuthenticationProps> = ( { expectedToken, onAuthorized } ) => {

    const [ isInLocalStorage, setLocalStorage ] = useState( window.localStorage.getItem( 'dp_viewer_token' ) === expectedToken );
    const [ textInput, setTextInput ] = useState<string>( '' );
    const [ error, setError ] = useState<string | undefined>();


    const handleAuthenticationAttempt = () => {
        setError( undefined );
        if ( textInput === expectedToken ) {
            window.localStorage.setItem( 'dp_viewer_token', expectedToken );
            setLocalStorage( true );
            if ( onAuthorized ) {
                onAuthorized();
            }
        } else {
            // ERROR
            setTextInput( '' );
            setError( 'Incorrect passphrase' )
        }
    }

    const handleInputChange = ( _e, newValue: string ) => {
        setTextInput( newValue );
    }

    const handleKeyPress = ( { nativeEvent }: React.KeyboardEvent ) => {
        if ( nativeEvent.key === 'Enter' && textInput.trim().length > 0 ) {
            handleAuthenticationAttempt();
        }
    }

    useEffect( () => {
        if ( isInLocalStorage && onAuthorized ) {
            onAuthorized();
        }
    }, [] );

    const logoSize = '60px';

    return (
        <div style={ { display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' } }>
            <div>
                <div
                    style={ {
                        margin: '45px',
                        width: logoSize,
                        height: logoSize,
                        backgroundImage: `url(${ UTZ_LOGO })`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat'
                    } }>
                </div>
            </div>
            <div style={ { flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                <div style={ { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } }>
                    <div>
                        <Text variant={ 'xxLarge' } nowrap block>
                            Authenication required
                        </Text>
                    </div>
                    <div style={ {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        marginTop: '25px',
                        width: '100%',
                        justifyContent: 'space-between'
                    } }>
                        <TextField errorMessage={ error } onKeyPress={ handleKeyPress } type={ 'password' }
                                   onChange={ handleInputChange }
                                   style={ { marginRight: '20px' } }/>
                        <PrimaryButton disabled={ textInput.trim().length === 0 }
                                       onClick={ handleAuthenticationAttempt }>Authorize</PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles: StylesMap = {};

export default SimpleAuthentication;