import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { MagazineResolverType, ReaderFactory } from '../service/emag-factory';
import { IEMagReader, IItemRef, IOpfPackage } from '@alchemisten/emag-core';
import PageFlowLayout, { FlowPageMap, IFlowPage, IPageFlowRef, KeyType } from './pageflow-layout';
import MagletPage from './maglet-page';
import { StylesMap } from '../types/style';
import { Vec2Like } from '../types/math';

import { Label } from 'office-ui-fabric-react/lib/Label';
import { Slider } from 'office-ui-fabric-react/lib/Slider';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { Icon } from '@fluentui/react/lib/Icon';

import { MOCK_MAGS }       from '../mock/magazines';
import { useIntl, FormattedMessage }         from 'react-intl';
import { useTranslations } from "../providers/translation-provider";

import { GridLoader } from 'react-spinners';

export interface IViewerProps {
    resolver?: MagazineResolverType;
    language?: string | null;
    context?: string | null;
    magazineId: string;
    domain: string;
    withMenu?: boolean;
}

export interface IReaderError {
    message: string;
}

export interface INavState {
    top?: IItemRef,
    right?: IItemRef,
    bottom?: IItemRef,
    left?: IItemRef
}

export interface INavigationItem {
    id: KeyType;
    title: string;
}

export interface NavigationMenu {

}

const languageKeys = [
    'de',
    'en',
    'fr',
    'pl',
    'cz',
    'es'
];

const contextOptions: IDropdownOption[] = [
    { key: 'holding', text: 'Master' },
    { key: 'gude', text: 'GUDE' },
    { key: 'guch', text: 'GUCH' },
    { key: 'gupl', text: 'GUPL' },
    { key: 'gufr', text: 'GUFR' },
    { key: 'guuk', text: 'GUUK' },
    { key: 'guus', text: 'GUUS' },
    { key: 'gumx', text: 'GUMX' },
    { key: 'gucn', text: 'GUCN' },
    { key: 'guho', text: 'GUHO' },
];

const LegacyMagazineViewer: FC<IViewerProps> = (
    {
        resolver = MagazineResolverType.API,
        magazineId,
        domain,
        language,
        context,
        withMenu = false
    } ) => {

    // global state
    const [ isLoading, setLoading ] = useState( false );
    const [ error, setError ] = useState<IReaderError | undefined>();
    const [ isMenuOpen, setMenuOpen ] = useState<boolean>( false );
    const [ tocOpen, setTocOpen ] = useState<boolean>( false );

    // magazine state
    const [ reader, setReader ] = useState<IEMagReader | undefined>();
    const [ magazine, setMagazine ] = useState<IOpfPackage | undefined>();
    const [ currentPage, setCurrentPage ] = useState<IItemRef | undefined>();
    const [ nextPage, setNextPage ] = useState<IItemRef | undefined>();
    const [ pages, setPages ] = useState<FlowPageMap>( {} );
    const [ pageIndex, setPageIndex ] = useState<IItemRef[]>([]);

    const [ index, setIndex ] = useState<number>( 1 );
    const [ windowSize, setWindowSize ] = useState<Vec2Like>( { x: window.innerWidth, y: window.innerHeight } );
    const [ currentContext, setCurrentContext ] = useState<string>( context );

    const intl = useIntl();

    const { setLanguage, currentLanguage, translations } = useTranslations();

    const languageOptions = useMemo(() => {
        return languageKeys.map<IDropdownOption>((lang) => {
            return {
                key: lang,
                text: `${intl.formatMessage({id: `__lang.${lang}`})} (${translations[lang][`__lang.${lang}`]})`
            }
        })
    }, [currentLanguage]);

    // pageflow
    const pageFlowRef = useRef<IPageFlowRef>( null );

    const updateIndex = ( key: KeyType ) => {
        console.log( 'Update Index', key );
        const index = magazine?.spine.items.findIndex( ( val ) => val.id === key );
        const page = magazine?.spine.items.find( ( val ) => val.id === key );
        if ( index ) {
            setIndex( index + 1 );
            setCurrentPage( page );
            console.log(page);
        } else {
            console.log( 'Index not found', key );
        }
    };

    const handleOpenMenu = () => {
        handleMenuToggle( true );
    }

    const handleCloseMenu = () => {
        handleMenuToggle( false );
    }

    const handleMenuToggle = async ( open: boolean ) => {
        console.log( 'Menu => ', open );
        // TODO animation
        console.log( 'Menu is', open ? 'open' : 'closed' );
        if ( !open ) {
            await setTocOpen( false );
        }
        setMenuOpen( open );
    }

    const handleTocOpen = () => {
        handleTocToggle( true );
    }

    const handleTocClose = () => {
        handleTocToggle( false );
    }

    const handleTocToggle = async ( open: boolean ) => {
        setTocOpen( open );
    }

    const handleWindowResize = () => {
        setWindowSize( { x: window.innerWidth, y: window.innerHeight } );
    }

    const handleMessage = ( event: MessageEvent ) => {
        if ( event.origin.startsWith( 'https://publish.utzgroup.com' ) ) {
            console.log(event.data);
            const data = JSON.parse( event.data );

            if ( data.type === 'doubletap' ) {
                handleOpenMenu();
            }
        }
    }

    const handleLanguageChange = ( _event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption ) => {
        if ( option ) {
            setLanguage( option.key + '' );
        }
    }

    const handleContextChange = ( _event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption ) => {
        if ( option ) {
            setCurrentContext( option.key + '' );
        }
    }

    const handleNavigationPreview = ( index: number ) => {
        setNextPage( {
            id: index
        } );
    }

    const handleNavigationChange = ( _event: MouseEvent, index: number ) => {
        const item = pageIndex[index-1];

        if(item) {
            handlePageNavigation( item.id );
        }

        setNextPage( undefined );
    }

    const handlePageNavigation = ( id: KeyType ) => {
        pageFlowRef.current.setPage( id );
    }

    useEffect( () => {
        // attach event listeners
        window.addEventListener( 'resize', handleWindowResize );
        window.addEventListener( 'message', handleMessage );
        return () => {
            window.removeEventListener( 'resize', handleWindowResize );
            window.removeEventListener( 'message', handleMessage );
        }
    }, [] );

    // get reader
    useEffect( () => {
        console.log( `Loading magazine ${ magazineId }` );
        // reset
        setLoading( true );
        setError( undefined );
        setReader( undefined );
        setMagazine( undefined );

        (async () => {
            const magReader = await ReaderFactory.ofType( resolver, domain, magazineId );
            setReader( magReader );

            await magReader.loadMetaData();
            setLoading( false );

            console.log( `Parsing metadata` );
            const container = magReader.getContainer();

            if ( !container ) {
                setError( {
                    message: 'Unable to parse Magazine'
                } );
            } else {
                if ( container.rootFiles.length > 0 ) {
                    const rootFile = container.rootFiles[ 0 ].content as IOpfPackage;
                    setMagazine( rootFile );

                    const pageResources = rootFile.spine.items
                        .map( ref => rootFile.manifest[ ref.id ] )
                        .filter( res => res.context && (res.context[currentContext] && res.context[currentContext] === 'include'));

                    setCurrentPage( pageResources[ 0 ] );
                    setIndex( 1 );
                    setPageIndex(pageResources);
                    setPages( pageResources
                        .reduce( ( stack, res, index ) => {
                        const page: IFlowPage = {
                            key: res.id
                        };
                        if ( index < pageResources.length - 1 ) {
                            page.right = pageResources[ index + 1 ].id as string;
                        }
                        if ( index > 0 ) {
                            page.left = pageResources[ index - 1 ].id as string;
                        }
                        stack[ page.key ] = page;
                        return stack;
                    }, {} as FlowPageMap ) );

                } else {
                    setError( {
                        message: 'Magazine does not contain any root files'
                    } );
                }
            }

            setLoading( false );
            console.log( `Parsing data finished` );
        })().then( () => { /* Done loading*/
        } )
            .catch( err => {
                setLoading( false );
                setError( { message: err.message } )
            } );
    }, [ magazineId ] );

    if ( isLoading ) {
        return (
            <div style={ styles.loading }>
                <GridLoader color={'#0F4279'}/>
                <h3><FormattedMessage id={'app.loading'}/></h3>
            </div>
        )
    }

    if ( error ) {
        return (
            <div>
                Error: { error?.message }
            </div>
        )
    }

    if ( !reader || !magazine || !currentPage ) {
        // null checks;
        return (
            <div>Error: Something wrong with reader</div>
        )
    }

    const { x: width, y: height } = windowSize;

    const topMenuHeight = 42;
    const paddingTop = 5;
    const topMenuHeightPx = `${ topMenuHeight }px`;

    return (
        <div style={ styles.wrapper } onTouchMoveCapture={ e => {
            e.stopPropagation();
            e.preventDefault();
        } }>
            { withMenu &&
            <div style={ { height: `${ topMenuHeight - paddingTop * 2 }px`, padding: `${ paddingTop }px 25px` } }>
                <div style={ { ...styles.flexFull } }>
                    <div style={ { flex: 1 } }>
                        <strong>
                            <FormattedMessage id={`magazines.${magazineId}`} defaultMessage={magazine.metaData.title}/> ({ contextOptions.find( item => item.key === currentContext )?.text })
                        </strong>
                    </div>
                    <div onClick={ () => handleMenuToggle( !isMenuOpen ) }>
                        <Icon iconName="Breadcrumb" style={ { fontSize: 38 } }/>
                    </div>
                </div>

            </div> }
            <div style={ {
                ...styles.navigationWrapper,
                top: withMenu ? topMenuHeightPx : 0,
                pointerEvents: isMenuOpen ? 'all' : 'none'
            } }>
                {
                    isMenuOpen && <div style={ {
                        ...styles.navigationStack,
                        height: withMenu ? `calc( 100vh - ${ topMenuHeightPx } )` : '100vh'
                    } }>
                        {
                            tocOpen && <div style={ styles.tocWrapper }>
                                <div style={ { ...styles.tocContent, ...styles.softShadow } }>
                                    <div style={ { display: 'flex', flexDirection: 'row' } }>
                                        <div style={ { flex: 1 } }>
                                            <Label style={ { fontSize: 24 } }><FormattedMessage id={'menu.navigation'}/></Label>
                                        </div>
                                        <div onClick={ handleTocClose } style={ { cursor: 'pointer' } }>
                                            <Icon iconName={ 'Cancel' }/>
                                        </div>
                                    </div>
                                    <div style={ { marginTop: '20px' } }>
                                        ...
                                        {
                                            MOCK_MAGS[ magazineId ] &&
                                            <div>
                                                <Label
                                                    style={ {
                                                        ...styles.link,
                                                        color: currentPage.id == MOCK_MAGS[ magazineId ].id ? 'blue' : 'black'
                                                    } }
                                                    onClick={ () => handlePageNavigation( MOCK_MAGS[ magazineId ].id ) }
                                                >{ MOCK_MAGS[ magazineId ].lang[ currentLanguage ] }</Label>
                                                <ul>
                                                    {
                                                        MOCK_MAGS[ magazineId ].children.map( toc1 => {
                                                            return (
                                                                <li key={ toc1.id } style={ styles.link }>
                                                                    <Label
                                                                        onClick={ () => handlePageNavigation( toc1.id ) }
                                                                        style={ {
                                                                            ...styles.link,
                                                                            padding: '2px',
                                                                            color: currentPage.id == toc1.id ? 'blue' : 'black'
                                                                        } }>{ toc1.lang[ currentLanguage ] }</Label>
                                                                    <ul>
                                                                        {
                                                                            toc1.children.map( toc2 => {
                                                                                return (
                                                                                    <li key={ toc2.id }
                                                                                        style={ styles.link }>
                                                                                        <Label
                                                                                            onClick={ () => handlePageNavigation( toc2.id ) }
                                                                                            style={ {
                                                                                                ...styles.link,
                                                                                                padding: '2px',
                                                                                                color: currentPage.id == toc2.id ? 'blue' : 'black'
                                                                                            } }>{ toc2.lang[ currentLanguage ] }</Label>
                                                                                    </li>
                                                                                )
                                                                            } )
                                                                        }
                                                                    </ul>
                                                                </li>
                                                            )
                                                        } )
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div style={ { display: 'flex', ...styles.navigationOverlay } }
                                     onClick={ handleTocClose }>

                                </div>
                            </div>
                        }
                        <div style={ { ...styles.softShadow } }>
                            <div style={ { ...styles.navigationContent } }>
                                <div style={ styles.flexFull }>
                                    <div style={ {
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        alignContent: 'baseline',
                                        cursor: 'pointer'
                                    } }
                                         onClick={ handleTocOpen }>
                                        <Icon iconName={ 'DOM' } style={ { fontSize: 22 } }/>
                                        <Label style={ { cursor: 'pointer' } }><FormattedMessage id={'menu.toc'}/></Label>
                                    </div>

                                    <div>
                                        <Dropdown
                                            label={ intl.formatMessage({id: 'menu.context'}) }
                                            placeHolder={ intl.formatMessage({id: 'menu.select-context'}) }
                                            selectedKey={ currentContext }
                                            options={ contextOptions }
                                            onChange={ handleContextChange }
                                        />
                                    </div>
                                    <div style={ { borderRight: 'rgba(0,0,0, 0.25) solid 1px', margin: '0px 15px' } }>

                                    </div>
                                    <div style={{marginRight: '45px'}}>
                                        <Dropdown
                                            label={ intl.formatMessage({id: 'app.language'}) }
                                            selectedKey={ currentLanguage }
                                            options={ languageOptions }
                                            onChange={ handleLanguageChange }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={ { ...styles.navigationOverlay } } onClick={ handleCloseMenu }>
                        </div>
                        <div style={ { ...styles.softShadow } }>
                            <div style={ { ...styles.navigationContent } }>
                                <div style={ styles.flexFull }>
                                    <div style={ { flex: 1 } }>
                                        <Slider
                                            label={ nextPage ? `${intl.formatMessage({id: 'menu.next-page'})}: ${ nextPage.id }` : `${intl.formatMessage({id: 'menu.navigation'})}:` }
                                            min={ 1 }
                                            max={ Object.keys( pages ).length }
                                            step={ 1 }
                                            value={ index }
                                            showValue
                                            snapToStep
                                            onChange={ handleNavigationPreview }
                                            onChanged={ handleNavigationChange }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
            <PageFlowLayout
                ref={ pageFlowRef }
                width={ width }
                height={ height }
                pages={ pages }
                initialPage={ currentPage.id }
                onPageChange={ updateIndex }
                renderPage={ ( key: KeyType ) => {
                    return <MagletPage language={ currentLanguage } width={ width } height={ height } reader={ reader }
                                       package={ magazine } id={ key }/>
                } }
            />
        </div>
    );
};

const styles: StylesMap = {
    flexFull: {
        display: 'flex',
        width: '100%'
    },
    softShadow: {
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 20px 0px'
    },
    link: {
        cursor: 'pointer'
    },
    wrapper: {
        position: 'relative'
    },
    nav: {
        position: 'absolute',
        color: 'rgba(0,0,0,0.4)',
        fontSize: '1em',
        bottom: 20,
        right: 20
    },
    tocWrapper: {
        zIndex: 1000,
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'row'
    },
    tocContent: {
        position: 'relative',
        padding: '35px',
        display: 'flex',
        width: '350px',
        height: '100vh',
        backgroundColor: 'white',
        flexDirection: 'column'
    },
    navigationWrapper: {
        position: 'absolute',
        zIndex: 100,
        left: 0,
        bottom: 0,
        right: 0
    },
    navigationStack: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    navigationOverlay: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0, 0.25)',
        cursor: 'pointer'
    },
    navigationContent: {
        position: 'relative',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        padding: '10px 25px'
    },
    loading: {
        display: 'flex',
        flexGrow: 1,
        height: '100vh',
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'column'
    }
};

export default LegacyMagazineViewer;

