"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FsFileCacheStrategy = void 0;
var abstract_file_cache_strategy_1 = require("../abstract-file-cache-strategy");
var FsFileCacheStrategy = /** @class */ (function (_super) {
    __extends(FsFileCacheStrategy, _super);
    function FsFileCacheStrategy(filePath) {
        var _this = _super.call(this, filePath) || this;
        // require native libraries
        _this.fs = require('fs');
        _this.path = require('path');
        // get base as  absolute path
        _this.basePath = _this.path.isAbsolute(_this.filePath)
            ? _this.filePath
            : _this.path.resolve(__dirname, _this.filePath);
        console.log("File cache " + _this.basePath);
        _this.fs.mkdirSync(_this.basePath, { recursive: true });
        return _this;
    }
    FsFileCacheStrategy.prototype.existsImpl = function (filepath) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.fs.existsSync(this.getAbsolutePath(filepath))];
            });
        });
    };
    FsFileCacheStrategy.prototype.getDataImpl = function (filepath) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.fs.readFileSync(this.getAbsolutePath(filepath))];
            });
        });
    };
    FsFileCacheStrategy.prototype.removeDataImpl = function (filepath) {
        return __awaiter(this, void 0, void 0, function () {
            var path;
            return __generator(this, function (_a) {
                path = this.getAbsolutePath(filepath);
                this.fs.unlinkSync(path);
                return [2 /*return*/, this.fs.existsSync(path)];
            });
        });
    };
    FsFileCacheStrategy.prototype.writeDataImpl = function (filepath, data) {
        return __awaiter(this, void 0, void 0, function () {
            var path;
            return __generator(this, function (_a) {
                path = this.getAbsolutePath(filepath);
                this.fs.mkdirSync(this.path.dirname(path), { recursive: true });
                this.fs.writeFileSync(path, data, { encoding: 'utf8' });
                return [2 /*return*/, this.fs.existsSync(path)];
            });
        });
    };
    FsFileCacheStrategy.prototype.getAbsolutePath = function (filepath) {
        return this.path.resolve(this.basePath, filepath);
    };
    return FsFileCacheStrategy;
}(abstract_file_cache_strategy_1.AbstractFileCacheStrategy));
exports.FsFileCacheStrategy = FsFileCacheStrategy;
