"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiEmagReader = void 0;
var abstract_e_mag_reader_delegate_1 = require("../abstract-e-mag-reader-delegate");
var axios_1 = require("axios");
var mime_types_1 = require("mime-types");
// TODO refactor to Request Library Proxy
var ApiEmagReader = /** @class */ (function (_super) {
    __extends(ApiEmagReader, _super);
    function ApiEmagReader(config) {
        var _a;
        var _this = _super.call(this) || this;
        _this.config = config;
        var _b = _this.config, instance = _b.instance, embed = _b.embed;
        var baseURL = _this.config.baseURL;
        _this.eventLoaderRequests = {};
        if ((_a = config.embed) === null || _a === void 0 ? void 0 : _a.useEventLoader) {
            var onMessageCallback = function (event) {
                var data = typeof event.data === 'object' ? event.data : JSON.parse(event.data);
                var url = data.url, type = data.type, _debug = data._debug, dataUri = data.dataUri;
                // this is for debugging
                if (type === 'getData' && _debug === true) {
                    console.log("Fetching debug url", url);
                    _this.instance.get(url, {
                        responseType: 'arraybuffer',
                    }).then(function (txt) {
                        var contentType = mime_types_1.lookup(url);
                        if (!contentType) {
                            contentType = 'application/json';
                        }
                        return "data:" + contentType + ";base64," + _this.arrayBufferToBase64(txt.data);
                    })
                        .then(function (debugDataUri) {
                        if (_this.eventLoaderRequests[url]) {
                            _this.eventLoaderRequests[url](debugDataUri);
                            delete _this.eventLoaderRequests[url];
                        }
                    });
                }
                if (type === 'app_getDataResponse') {
                    if (_this.eventLoaderRequests[url]) {
                        _this.eventLoaderRequests[url](dataUri);
                        delete _this.eventLoaderRequests[url];
                    }
                }
            };
            window.addEventListener('message', onMessageCallback);
        }
        if (instance) {
            _this.instance = instance;
        }
        else {
            if (!baseURL) {
                throw new Error("Either provide baseURL or an instance for the request library");
            }
            if (embed === null || embed === void 0 ? void 0 : embed.magazineUrl) {
                baseURL = undefined;
            }
            _this.instance = axios_1.default.create({
                baseURL: baseURL,
            });
        }
        return _this;
    }
    ApiEmagReader.prototype.arrayBufferToBase64 = function (buffer) {
        var decoder = new TextDecoder('utf-8');
        return btoa(unescape(encodeURIComponent(decoder.decode(buffer))));
    };
    ApiEmagReader.prototype.getData = function (url) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var contentBaseUrl, requestUrl_1, payload, dataPromise, dataUrl, _e, mimeType, data, decoded, data;
            var _this = this;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!(((_a = this.config.embed) === null || _a === void 0 ? void 0 : _a.useEventLoader) === true)) return [3 /*break*/, 2];
                        contentBaseUrl = ((_b = this.config.embed) !== null && _b !== void 0 ? _b : {}).contentBaseUrl;
                        requestUrl_1 = contentBaseUrl ? "" + contentBaseUrl + (contentBaseUrl.endsWith('/') ? '' : '/') + url : url;
                        payload = { type: 'getData', url: requestUrl_1 };
                        if (window.ReactNativeWebView) {
                            window.ReactNativeWebView.postMessage(JSON.stringify(payload));
                        }
                        else {
                            window.postMessage(__assign({ _debug: true }, payload));
                        }
                        dataPromise = new Promise(function (res, rej) {
                            _this.eventLoaderRequests[requestUrl_1] = res;
                        });
                        return [4 /*yield*/, dataPromise];
                    case 1:
                        dataUrl = _f.sent();
                        _e = (_d = (_c = /data:(?<mimeType>[^;]+);base64,(?<data>.*)/.exec(dataUrl)) === null || _c === void 0 ? void 0 : _c.groups) !== null && _d !== void 0 ? _d : {}, mimeType = _e.mimeType, data = _e.data;
                        decoded = decodeURIComponent(escape(atob(data)));
                        switch (mimeType) {
                            case 'application/json':
                                return [2 /*return*/, JSON.parse(decoded)];
                            default:
                                return [2 /*return*/, decoded];
                        }
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.instance.get(url, {
                            responseType: 'json'
                        })];
                    case 3:
                        data = (_f.sent()).data;
                        return [2 /*return*/, data];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ApiEmagReader.prototype.loadMetaData = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var magazineUrl, data, spine, content;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.container = {
                            rootFiles: []
                        };
                        this.mimeType = 'application/x-emag-api';
                        magazineUrl = "/magazines/" + this.config.magazineId;
                        if ((_a = this.config.embed) === null || _a === void 0 ? void 0 : _a.magazineUrl) {
                            magazineUrl = this.config.embed.magazineUrl;
                        }
                        return [4 /*yield*/, this.getData(magazineUrl)];
                    case 1:
                        data = _b.sent();
                        spine = {
                            items: [],
                            nav: {
                                chapters: []
                            }
                        };
                        content = {
                            version: '1',
                            uniqueIdentifier: 'id',
                            lang: 'de',
                            prefix: '',
                            i18n: data.i18n,
                            metaData: {
                                id: this.config.magazineId + "/root-opf",
                                title: data.title
                                // TODO additional info
                            },
                            manifest: {},
                            bindings: {},
                            resolveManifestItem: this.resolveManifestItem.bind(this),
                            spine: spine
                        };
                        data.maglets.forEach(function (maglet) {
                            var _a;
                            var navChapter = __assign(__assign({}, maglet.info), { i18n: maglet.i18n, pages: [] });
                            (_a = spine.nav) === null || _a === void 0 ? void 0 : _a.chapters.push(navChapter);
                            maglet.pages
                                .filter(function (p) { return p.source.uri; })
                                .forEach(function (page) {
                                var _a, _b;
                                var navPage = __assign(__assign({}, page.info), { i18n: page.i18n });
                                navChapter.pages.push(navPage);
                                var source = page.source, _c = page.info, id = _c.id, title = _c.title, i18n = page.i18n, _d = page.availableLanguages, availableLanguages = _d === void 0 ? [] : _d;
                                var uri = (_a = source.uri) !== null && _a !== void 0 ? _a : '';
                                if ((_b = _this.config.embed) === null || _b === void 0 ? void 0 : _b.contentBaseUrl) {
                                    var contentBaseUrl = _this.config.embed.contentBaseUrl;
                                    uri = contentBaseUrl + "/" + id + ".html";
                                }
                                content.manifest[id] = {
                                    id: page.info.id,
                                    href: uri,
                                    mediaType: 'text/html',
                                    i18n: i18n !== null && i18n !== void 0 ? i18n : { en: title },
                                    availableLanguages: availableLanguages,
                                    context: __assign(__assign({}, (Object.keys(page.context || {}).length > 0 ? {} : maglet.context)), page.context)
                                };
                                spine.items.push({
                                    id: page.info.id
                                });
                            });
                        });
                        this.container.rootFiles.push({
                            fullPath: magazineUrl,
                            mediaType: 'application/x-mag-api-opf+json',
                            content: content
                        });
                        return [2 /*return*/, this.container];
                }
            });
        });
    };
    ApiEmagReader.prototype.resolveManifestItem = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, null];
            });
        });
    };
    ApiEmagReader.prototype.getPackageFileAsString = function (relativePath) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getData(relativePath)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return ApiEmagReader;
}(abstract_e_mag_reader_delegate_1.AbstractEMagReaderDelegate));
exports.ApiEmagReader = ApiEmagReader;
