"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractFileCacheStrategy = void 0;
var AbstractFileCacheStrategy = /** @class */ (function () {
    function AbstractFileCacheStrategy(filePath) {
        this.filePath = filePath;
        this.cache = {};
    }
    AbstractFileCacheStrategy.prototype.itemExists = function (href) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, !!this.cache[href]];
            });
        });
    };
    AbstractFileCacheStrategy.prototype.removeItem = function (href) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.itemExists(href)];
                    case 1:
                        _a = (_b.sent());
                        if (!_a) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.removeDataImpl(href)];
                    case 2:
                        _a = (_b.sent());
                        _b.label = 3;
                    case 3:
                        if (_a) {
                            delete this.cache[href];
                            return [2 /*return*/, true];
                        }
                        return [2 /*return*/, false];
                }
            });
        });
    };
    AbstractFileCacheStrategy.prototype.getItemInfo = function (href) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!this.itemExists(href)) {
                    throw new Error("Cache: Item " + href + " is not defined");
                }
                return [2 /*return*/, this.cache[href]];
            });
        });
    };
    AbstractFileCacheStrategy.prototype.getItem = function (href) {
        return __awaiter(this, void 0, void 0, function () {
            var resource, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.itemExists(href)) {
                            throw new Error("Cache: Item " + href + " is not defined");
                        }
                        resource = this.cache[href];
                        if (!!resource.content) return [3 /*break*/, 2];
                        _a = resource;
                        return [4 /*yield*/, this.getDataImpl(resource.href)];
                    case 1:
                        _a.content = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/, this.cache[href]];
                }
            });
        });
    };
    AbstractFileCacheStrategy.prototype.putItemInfo = function (info) {
        return __awaiter(this, void 0, void 0, function () {
            var existed;
            return __generator(this, function (_a) {
                existed = this.itemExists(info.href);
                this.cache[info.href] = info;
                return [2 /*return*/, existed];
            });
        });
    };
    AbstractFileCacheStrategy.prototype.putItem = function (resource) {
        return __awaiter(this, void 0, void 0, function () {
            var existed;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!resource) {
                            throw new Error('Cache: Resource must not be null');
                        }
                        if (!resource.content) {
                            throw new Error("Cache: Resource (" + resource.href + ") content must be defined, for empty items, consider \"putItemInfo\" instead");
                        }
                        this.cache[resource.href] = resource;
                        return [4 /*yield*/, this.existsImpl(resource.href)];
                    case 1:
                        existed = _a.sent();
                        return [4 /*yield*/, this.writeDataImpl(resource.href, resource.content)];
                    case 2:
                        if (!(_a.sent())) {
                            throw new Error("Cache: Unable to write file " + resource.href);
                        }
                        return [2 /*return*/, existed];
                }
            });
        });
    };
    AbstractFileCacheStrategy.prototype.release = function (href) {
        return __awaiter(this, void 0, void 0, function () {
            var existed;
            return __generator(this, function (_a) {
                if (!this.cache[href]) {
                    throw new Error("Cache: Item (" + href + ") not defined");
                }
                existed = !!this.cache[href].content;
                // release it
                this.cache[href].content = null;
                return [2 /*return*/, existed];
            });
        });
    };
    return AbstractFileCacheStrategy;
}());
exports.AbstractFileCacheStrategy = AbstractFileCacheStrategy;
