"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.xmlNodeValue = exports.parseXML = void 0;
var xml2js_1 = require("xml2js");
exports.parseXML = function (data) {
    return new Promise(function (resolve, reject) {
        xml2js_1.parseString(data, function (err, result) {
            if (err) {
                return reject(err);
            }
            resolve(result);
        });
    });
};
exports.xmlNodeValue = function (xmlNode, key, defaultValue) {
    var val = xmlNode[key];
    if (val) {
        if (Array.isArray(val)) {
            return val.length > 0
                ? val[0]._ || val[0]
                : defaultValue;
        }
        else {
            return val;
        }
    }
    return defaultValue;
};
