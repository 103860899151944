import React, { FC, useState } from 'react';

import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import LegacyMagazineViewer from './components/legacy-magazine-viewer';
import SimpleAuthentication from './components/simple-authentication';

function useQuery() {
    return new URLSearchParams( useLocation().search );
}

const HUMAN_TOKEN = 'utz2020';
const URL_TOKEN = '882$2§jw32H';

const MagazineWrapper: FC<RouteComponentProps> = ( { match } ) => {
    const query = useQuery();
    const language = query.get( 'lang' ) || 'de';
    const context = query.get( 'context' ) || 'holding';
    const withMenu = (query.get('menu') || 'true') === 'true';
    const token = query.get('token');

    const domains = window.location.host.split(/^([\w-]+)\./);
    const domain = (domains.length>1 && !domains[1].startsWith('192')) ? domains[1] : query.get('domain') || '';

    const [ isAuthenticated, setAuthenticated ] = useState(token === URL_TOKEN);

    const handleAuthorize = () => {
        setAuthenticated(true);
    };

    if ( !match || !match.params || !match.params['magazineId'] ) {
        return <div>No magazine selected</div>;
    }

    if(!isAuthenticated) {
        return (
            <SimpleAuthentication expectedToken={HUMAN_TOKEN} onAuthorized={handleAuthorize} />
        )
    }

    return (
        <LegacyMagazineViewer magazineId={ match.params['magazineId'] } domain={domain} language={ language } withMenu={withMenu} context={context}/>
    );
};

export default withRouter( MagazineWrapper );
