"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMagParser = void 0;
var EMagParser = /** @class */ (function () {
    function EMagParser(reader) {
        this.reader = reader;
    }
    EMagParser.fromReader = function (reader) {
        return new this(reader);
    };
    return EMagParser;
}());
exports.EMagParser = EMagParser;
