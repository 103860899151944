import { BaseEMagReader, IEMagReader } from '@alchemisten/emag-core';
import {IAPiEmagReaderEmbedProps} from "@alchemisten/emag-core/lib/reader/impl/access/api-emag-reader";

export enum MagazineResolverType {
    API = 'api'
}

// TODO refactor into CORE
export abstract class ReaderFactory {
    public static async fromAPI(tenantDomain: string, magazineId: string,  embed?: IAPiEmagReaderEmbedProps): Promise<IEMagReader> {
        return BaseEMagReader.fromAPI( {
            embed,
            baseURL: `https://publisher.api.medialake.io/${tenantDomain}`,
            magazineId
        } );
    }
    
    public static async ofType(type: MagazineResolverType, tenantDomain: string, magazineId: string, embed?: IAPiEmagReaderEmbedProps): Promise<IEMagReader> {
        switch(type) {
            case MagazineResolverType.API:
                return this.fromAPI(tenantDomain, magazineId, embed);
            default:
                throw new Error('Magazine resolver type not implemented');
        }
    }
}

