import React, { ReactNode, forwardRef, useState, useEffect, useRef, RefObject } from 'react';

import animejs                       from 'animejs';
import { StylesMap }                 from '../types/style';
import { TouchLike, Vec2, Vec2Like } from '../types/math';

import { IconButton } from '@fluentui/react';

export enum PageFlowDirection {
    CONTENT = 'content',
    TOP = 'top',
    RIGHT = 'right',
    BOTTOM = 'bottom',
    LEFT = 'left'
}

export enum PageFlow {
    FREE = 'free',
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical'
}

export type KeyType = string | number;

export interface IFlowPage {
    key: KeyType;
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
}

export type FlowPageMap = {
    [key: string]: IFlowPage
}

export type RenderFlowPageFn = (key: KeyType) => ReactNode;

export interface IPageFlowLayoutProps {
    width: number;
    height: number;

    flowThreshold?: number;
    flowSpeed?: number;

    pages: FlowPageMap;
    renderPage: RenderFlowPageFn;
    initialPage: KeyType;

    onPageChange?: (key: KeyType) => void;
}

export interface IPageFlowRef {
    setPage: (key: KeyType) => Promise<void>;
}

const updateElementSize = (ref: RefObject<HTMLElement>, width: number, height: number): void => {
    if (ref.current) {
        const { style } = ref.current;
        style.width = `${ width }px`;
        style.height = `${ height }px`;
    }
};

const updateElementOffset = (ref: RefObject<HTMLElement>, offset: Vec2Like): void => {
    if (ref.current) {
        const { style } = ref.current;
        style.left = `${ offset.x }px`;
        style.top = `${ offset.y }px`;
    }
};

const updateScrollOffset = (container: RefObject<HTMLElement>, scroll: RefObject<Vec2Like>, width: number, height: number) => {
    if (container.current && scroll.current) {
        const { x, y } = scroll.current;
        const { style } = container.current;
        style.transform = `translate(-${ x * width }px, -${ y * height }px)`
        //style.left = `-${ x * width }px`;
        //style.top = `-${ y * height }px`;
    }
};

const PageFlowLayout = forwardRef<IPageFlowRef, IPageFlowLayoutProps>((props, ref) => {

    const { pages, renderPage, width, height, flowThreshold = 0.33, flowSpeed = 400, initialPage, onPageChange } = props;

    if (!pages[initialPage]) {
        return null;
    }

    const [aPage, setAPage] = useState<KeyType | undefined>(initialPage);
    const [bPage, setBPage] = useState<KeyType | undefined>();
    const [contentBuffer, setContentBuffer] = useState<boolean>(true);

    const currentPage = contentBuffer ? aPage : bPage;
    const nextPage = contentBuffer ? bPage : aPage;

    const wrapperRef = useRef<HTMLDivElement>(null);

    const contentARef = useRef<HTMLDivElement>(null);
    const contentBRef = useRef<HTMLDivElement>(null);


    const containerRef = useRef<HTMLDivElement>(null);
    const scrollRef = useRef<Vec2Like>({ x: 1, y: 1 });
    const touchesRef = useRef<Vec2Like>({ x: 0, y: 0 });
    const touchStartRef = useRef<Vec2Like>();

    const captureRef = useRef<boolean>(false);

    const flowLockRef = useRef<PageFlow>(PageFlow.FREE);
    const flowNextDirectionRef = useRef<PageFlowDirection>(PageFlowDirection.CONTENT);

    const handleMessage = (event: MessageEvent) => {
        if (event.origin.startsWith('https://publish.utzgroup.com')) {
            const data = JSON.parse(event.data);

            if (data.type === 'keyup') {
                handleKey(data);
            }

            return;
            /*
            if (data.type === 'touchstart') {
                captureRef.current = true;
                handleTouchStart(data);
            }

            if (data.type === 'touchend') {
                if (captureRef.current) {
                    handleTouchEnd();
                }
                captureRef.current = false;
            }

            if (data.type === 'touchmove') {
                if (captureRef.current) {
                    handleTouchMove(data);
                }
            }
             */
        }
    };

    const handleTouchStart = (event: TouchLike) => {
        // reset flow
        flowLockRef.current = PageFlow.FREE;
        flowNextDirectionRef.current = PageFlowDirection.CONTENT;

        // save initials
        touchesRef.current = Vec2.fromTouch(event);
        touchStartRef.current = (touchesRef.current as Vec2).clone();
    };

    const handleTouchEnd = () => {
        const touchStart = touchStartRef.current || Vec2.zero();
        const movedFinger = Vec2.from(touchesRef.current || Vec2.zero());

        const dist = movedFinger.sub(touchStart).len();

        let normalizedDist = 0;

        switch (flowLockRef.current) {
            case PageFlow.HORIZONTAL:
                normalizedDist = dist / width;
                break;
            case PageFlow.VERTICAL:
                normalizedDist = dist / height;
                break;
            default:
                return;
            // noop
        }

        if (normalizedDist < flowThreshold) {
            flowNextDirectionRef.current = PageFlowDirection.CONTENT;
        }

        triggerAnimationToNextFlow().then(() => {
        });
    };

    const handleTouchMove = (event: TouchLike) => {
        const touchStart = touchStartRef.current || Vec2.zero();
        const movedFinger = Vec2.fromTouch(event);

        const dist = movedFinger.sub(touchStart);

        // check axis locking
        if (flowLockRef.current === PageFlow.FREE) {
            if (dist.len() > 10) {
                const direction = dist.normalize().dot({ x: 1, y: 0 });
                flowLockRef.current = Math.abs(direction) < 0.5 ? PageFlow.VERTICAL : PageFlow.HORIZONTAL;
            }
        }

        if (flowLockRef.current !== PageFlow.FREE) {
            const delta = Vec2.from(touchesRef.current).sub(movedFinger).mul({ x: 1 / width, y: 1 / height });

            scrollRef.current = Vec2.from(scrollRef.current).add({
                x: flowLockRef.current === PageFlow.HORIZONTAL ? delta.x : 0,
                y: flowLockRef.current === PageFlow.VERTICAL ? delta.y : 0
            }).clamp(
                {
                    x: 0,
                    y: 1 // was 0, TODO CLAMP
                },
                {
                    x: 2,
                    y: 1 // was 2, TODO CLAMP
                }
            );

            const direction = new Vec2(
                flowLockRef.current === PageFlow.HORIZONTAL ? dist.x : 0,
                flowLockRef.current === PageFlow.VERTICAL ? dist.y : 0
            ).normalize().dot({
                x: flowLockRef.current === PageFlow.HORIZONTAL ? 1 : 0,
                y: flowLockRef.current === PageFlow.VERTICAL ? 1 : 0
            });

            const nextDirection = flowLockRef.current === PageFlow.HORIZONTAL
                ? direction > 0 ? PageFlowDirection.LEFT : PageFlowDirection.RIGHT
                : direction > 0 ? PageFlowDirection.TOP : PageFlowDirection.BOTTOM;

            if (flowNextDirectionRef.current !== nextDirection) {
                flowNextDirectionRef.current = nextDirection;

                updateNextElement();
            }
        }

        touchesRef.current = movedFinger;
        updateContainerScroll();
    };

    const handleKey = (event: KeyboardEvent) => {
        let nextDirection = PageFlowDirection.CONTENT;
        switch (event.key) {
            case 'ArrowLeft':
                nextDirection = PageFlowDirection.LEFT;
                break;
            case 'ArrowRight':
                nextDirection = PageFlowDirection.RIGHT;
                break;
            default:
                return;
        }

        flowNextDirectionRef.current = nextDirection;
        updateNextElement();
        triggerAnimationToNextFlow().then(() => {
        });
    };

    const handleDirectionChange = (nextDirection: PageFlowDirection) => () => {
        flowNextDirectionRef.current = nextDirection;
        updateNextElement();
        triggerAnimationToNextFlow().then(() => {
        });
    }

    const updateNextElement = () => {
        if (currentPage && flowNextDirectionRef.current) {
            const currentInfo = pages[currentPage];

            const choiceMap = {
                [PageFlowDirection.CONTENT]: currentInfo.key,
                [PageFlowDirection.TOP]: currentInfo.top,
                [PageFlowDirection.RIGHT]: currentInfo.right,
                [PageFlowDirection.BOTTOM]: currentInfo.bottom,
                [PageFlowDirection.LEFT]: currentInfo.left
            };

            if (contentBuffer) {
                setBPage(choiceMap[flowNextDirectionRef.current]);
            } else {
                setAPage(choiceMap[flowNextDirectionRef.current]);
            }

            const flowPositions = {
                [PageFlowDirection.CONTENT]: { x: width, y: height },
                [PageFlowDirection.TOP]: { x: width, y: 0 },
                [PageFlowDirection.RIGHT]: { x: width * 2, y: height },
                [PageFlowDirection.BOTTOM]: { x: width, y: height * 2 },
                [PageFlowDirection.LEFT]: { x: 0, y: height }
            };

            updateElementOffset(contentBuffer ? contentBRef : contentARef, flowPositions[flowNextDirectionRef.current]);
        }
    }

    const updateContainerScroll = () => {
        updateScrollOffset(containerRef, scrollRef, width, height);
    };

    const triggerAnimationToNextFlow = async () => {
        // console.log( 'Begin Animation' );
        let direction = { x: 1, y: 1 };
        switch (flowNextDirectionRef.current) {
            case PageFlowDirection.TOP:
                direction = { x: 1, y: 0 };
                break;
            case PageFlowDirection.RIGHT:
                direction = { x: 2, y: 1 };
                break;
            case PageFlowDirection.BOTTOM:
                direction = { x: 1, y: 2 };
                break;
            case PageFlowDirection.LEFT:
                direction = { x: 0, y: 1 };
                break;
        }
        const instance = animejs({
            targets: scrollRef.current,
            easing: 'easeInOutCubic',
            x: direction.x,
            y: direction.y,
            duration: flowSpeed,
            update: updateContainerScroll
        });

        await instance.finished;

        if (flowNextDirectionRef.current !== PageFlowDirection.CONTENT) {
            // move old offscreen
            updateElementOffset(contentBuffer ? contentARef : contentBRef, { x: 0, y: 0 });

            // put "new" content to center, update scroll and offset immediate
            updateElementOffset(contentBuffer ? contentBRef : contentARef, { x: width, y: height });

            scrollRef.current = { x: 1, y: 1 };
            updateContainerScroll();


            const newPage = contentBuffer ? bPage : aPage;
            if (onPageChange && newPage) {
                onPageChange(newPage);
            }

            setContentBuffer(!contentBuffer);
        }
    };

    useEffect(() => {
        // console.log( 'Updating size' );
        // update container size
        updateElementSize(containerRef, width * 3, height * 3);

        [wrapperRef, contentARef, contentBRef].forEach(ref => {
            updateElementSize(ref, width, height);
        });

        // update positions
        updateElementOffset(contentARef, { x: width, y: height });
        updateElementOffset(contentBRef, { x: width * 2, y: height });

        // update scrollPos, adjust for size
        updateScrollOffset(containerRef, scrollRef, width, height);

    }, [width, height]);

    useEffect(() => {
        // console.log( 'Hooking events' );

        // add callbacks to outer world
        (ref as any).current = {
            setPage: (key) => {

                console.log('Navigate TO', key);

                /*
                 if(contentBuffer) {
                 setAPage(key);
                 } else {
                 setBPage(key);
                 }
                 */
                setAPage(key);
                setBPage(key);

                if (onPageChange) {
                    onPageChange(key);
                }

                setContentBuffer(!contentBuffer);
            }
        }

        if (containerRef.current) {
            containerRef.current.addEventListener('touchmove', handleTouchMove as any);
            containerRef.current.addEventListener('touchstart', handleTouchStart as any);
            containerRef.current.addEventListener('touchend', handleTouchEnd);
            window.addEventListener('keyup', handleKey);
        }
        window.addEventListener('message', handleMessage);

        return () => {
            // console.log( 'Removing events' );
            if (containerRef.current) {
                containerRef.current.removeEventListener('touchmove', handleTouchMove as any);
                containerRef.current.removeEventListener('touchstart', handleTouchStart as any);
                containerRef.current.removeEventListener('touchend', handleTouchEnd);
            }
            window.removeEventListener('keyup', handleKey);
            window.removeEventListener('message', handleMessage)
        }
    });

    const aContent = aPage && pages[aPage] ? renderPage(aPage) : null;
    const bContent = bPage && pages[bPage] ? renderPage(bPage) : null;

    const hasLeft = !!pages[currentPage].left;
    const hasRight = !!pages[currentPage].right;

    return (
        <div style={ styles.wrapper } ref={ wrapperRef }>
            <div style={ styles.content } ref={ containerRef }>
                <div style={ styles.element } ref={ contentARef }>{ aContent }</div>
                <div style={ styles.element } ref={ contentBRef }>{ bContent }</div>
            </div>
            <div style={ styles.arrows }>
                <div style={ styles.verticalCenter }>
                    <div style={ styles.arrowStretch }/>
                    <div style={ { ...styles.arrow } }>
                        {
                            hasLeft &&
                            <IconButton iconProps={ {
                                iconName: 'ChevronLeft'
                            } } onClick={handleDirectionChange(PageFlowDirection.LEFT)}/>
                        }
                    </div>
                    <div style={ styles.arrowStretch }/>
                </div>
                <div style={ styles.arrowStretch }/>
                <div style={ styles.verticalCenter }>
                    <div style={ styles.arrowStretch }/>
                    <div style={ { ...styles.arrow } }>
                        {
                            hasRight &&
                            <IconButton size={50} iconProps={ {
                                iconName: 'ChevronRight',
                            } } onClick={handleDirectionChange(PageFlowDirection.RIGHT)}/>
                        }
                    </div>
                    <div style={ styles.arrowStretch }/>
                </div>
            </div>
        </div>
    );
});

const styles: StylesMap = {
    wrapper: {
        backgroundColor: 'grey',
        overflow: 'hidden',
        position: 'relative'
    },
    content: {
        position: 'absolute',
        transition: 'all 0s ease 0s'
    },
    element: {
        position: 'absolute'
    },
    arrows: {
        position: 'absolute',
        display: 'flex',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: "none"
    },
    arrowStretch: {
        flex: 1,
        pointerEvents: "none"
    },
    arrow: {
        cursor: 'pointer',
        pointerEvents: 'auto',
        margin: 30,
        padding: 10,
        userSelect: 'none'
    },
    verticalCenter: {
        display: "flex",
        flexDirection: 'column'
    }
};

export default PageFlowLayout;
