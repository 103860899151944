"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseEMagReader = void 0;
var api_emag_reader_1 = require("./access/api-emag-reader");
var file_emag_reader_1 = require("./access/file-emag-reader");
var zip_emag_reader_1 = require("./access/zip-emag-reader");
var BaseEMagReader = /** @class */ (function () {
    function BaseEMagReader(delegate) {
        this.delegate = delegate;
    }
    BaseEMagReader.fromURL = function (url) {
        var zipReader = new zip_emag_reader_1.ZipEMagReader(url);
        return new this(zipReader);
    };
    BaseEMagReader.fromFolder = function (path) {
        var fileReader = new file_emag_reader_1.FileEMagReader(path);
        return new this(fileReader);
    };
    BaseEMagReader.fromAPI = function (config) {
        var apiReader = new api_emag_reader_1.ApiEmagReader(config);
        return new this(apiReader);
    };
    // delegated methods
    BaseEMagReader.prototype.loadMetaData = function () {
        return this.delegate.loadMetaData();
    };
    BaseEMagReader.prototype.getMimeType = function () {
        return this.delegate.getMimeType();
    };
    BaseEMagReader.prototype.getContainer = function () {
        return this.delegate.getContainer();
    };
    return BaseEMagReader;
}());
exports.BaseEMagReader = BaseEMagReader;
