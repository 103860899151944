export interface Vec2Like {
    x: number;
    y: number;
}


export interface ClientPos {
    clientX: number;
    clientY: number;
}
export interface TouchLike {
    touches: ClientPos[];
}

export class Vec2 implements Vec2Like {

    static zero(): Vec2 {
        return new Vec2(0,0);
    }

    static uniform(axis: number): Vec2 {
        return new Vec2(axis, axis);
    }

    static fromWheel(event: WheelEvent, scale: number = 1): Vec2 {
        return new Vec2(event.deltaX * scale, event.deltaY * scale);
    }

    static fromTouch(event: TouchLike, scale: number = 1, index: number = 0): Vec2 {
        if(!event || event.touches.length === 0) {
            return this.zero();
        }

        const touch = event.touches[index];

        if(touch) {
            return new Vec2(touch.clientX * scale, touch.clientY * scale);
        }

        return this.zero();
    }

    static from(vec: Vec2Like): Vec2 {
        return new Vec2(vec.x, vec.y);
    }

    constructor( public x: number, public y: number ) {
    }

    public clone(): Vec2 {
        return new Vec2( this.x, this.y );
    }

    public add( other: Vec2Like ): Vec2 {
        return new Vec2( this.x + other.x, this.y + other.y );
    }

    public mul(other: Vec2Like): Vec2 {
        return new Vec2(this.x * other.x, this.y * other.y);
    }

    public mulScalar( scalar: number ): Vec2 {
        return new Vec2( this.x * scalar, this.y * scalar );
    }

    public normalize(): Vec2 {
        return this.mulScalar( 1 / this.len() );
    }

    public dot( other: Vec2Like ): number {
        return (this.x * other.x) + (this.y * other.y);
    }

    public sub( other: Vec2Like ): Vec2 {
        return new Vec2( this.x - other.x, this.y - other.y );
    }

    public len(): number {
        return Math.sqrt( (this.x * this.x) + (this.y * this.y) );
    }

    public dist( other: Vec2 ): number {
        return this.sub( other ).len();
    }

    public clamp(min: Vec2Like, max: Vec2Like): Vec2 {
        return new Vec2( clamp(this.x, min.x, max.x), clamp(this.y, min.y, max.y) );
    }
}

export const clamp = (val: number, min: number, max: number) => {
    return Math.min(Math.max(val, min), max);
};