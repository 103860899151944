import { useEffect, useState } from 'react';
import { Vec2Like } from '../types/math';

export const useWindowDimensions = () => {
    const [ windowSize, setWindowSize ] = useState<Vec2Like>( { x: window.innerWidth, y: window.innerHeight } );

    const handleWindowResize = () => {
        setWindowSize( { x: window.innerWidth, y: window.innerHeight } );
    }

    useEffect( () => {
        window.addEventListener( 'resize', handleWindowResize );
        window.addEventListener( 'orientationchange', handleWindowResize );
        return () => {
            window.removeEventListener( 'resize', handleWindowResize );
            window.removeEventListener( 'orientationchange', handleWindowResize );
        }
    }, [] );

    return windowSize;
}