import { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';

export type SearchUpdateMap = { [key: string]: string };

export const useQuery = () => {
    const { location, push  } = useHistory();
    const { url, params } = useRouteMatch();

    const search = useMemo(() => {
        const urlSearchParams = new URLSearchParams(location.search);

        const query = {};
        urlSearchParams.forEach((value, key) => {
            query[key] = value;
        });

        return {
            urlSearchParams,
            query
        };
    }, [location.search]);

    const updateSearch = (params: SearchUpdateMap, replace: boolean = false) => {

        const urlSearch = replace ? new URLSearchParams() : search.urlSearchParams;

        Object.keys(params).forEach((key) => {
            urlSearch.set(key, params[key]);
        });

        push( {
            pathname: location.pathname,
            search: urlSearch.toString()
        });
    }

    return {
        url,
        params,
        search,
        updateSearch,
        location
    }
}