import React, {FC, useEffect, useMemo, useState} from "react";
import {MagazineOutlet} from "./magazine-outlet";
import {useQuery} from "../hooks/query";
import SimpleAuthentication from "../components/simple-authentication";
import {useRouteMatch} from "react-router";
import {useTranslations} from "../providers/translation-provider";
import {IAPiEmagReaderEmbedProps} from "@alchemisten/emag-core/lib/reader/impl/access/api-emag-reader";

export interface MagazineRouterProps {

}

const HUMAN_TOKEN = 'utz2020';
const URL_TOKEN = 'e81eca26-7b3e-4fa4-85f9-658fb0a323be';

const ignoreExtraKeys = ['context', 'lang', 'page', 'menu', 'token', 'magazinePath', 'contentBaseUrl', 'domain'];

export const MagazineRouter: FC<MagazineRouterProps> = () => {

    const {search: {query}, updateSearch} = useQuery();

    const match = useRouteMatch();

    const language = query['lang'] || 'en';
    const context = query['context'] || 'holding';
    const pageKey = query['page'];
    const withMenu = (query['menu'] || 'true') === 'true';
    const token = query['token'];
    const magazineId = match?.params['magazineId'] ?? query['magazineId'];
    const magazineUrl = query['magazinePath'] && decodeURIComponent(query['magazinePath']);
    const contentBaseUrl = query['contentBaseUrl'] && decodeURIComponent(query['contentBaseUrl']);
    const useEventLoader = query['eventLoader'] && query['eventLoader'] === 'true';

    const embedded: IAPiEmagReaderEmbedProps | undefined = (magazineUrl || contentBaseUrl || useEventLoader)
        ? {
            magazineUrl,
            contentBaseUrl,
            useEventLoader
        }
        : undefined;

    const domains = window.location.host.split(/^([\w-]+)\./);
    const domain = (domains.length > 1 && !domains[1].startsWith('192')) ? domains[1] : query['domain'] || '';

    const leftOverQueryKeys = useMemo(() => {
        return Object.keys(query).filter((key) => !ignoreExtraKeys.includes(key));
    }, [query]);

    const extraParams = useMemo(() => {
        return leftOverQueryKeys.reduce((stack, key) => {
            stack[key] = query[key];
            return stack;
        }, {});
    }, [leftOverQueryKeys.join('')]);

    const [isAuthenticated, setAuthenticated] = useState(token === URL_TOKEN);
    const {currentLanguage} = useTranslations();

    useEffect(() => {
        if (language !== currentLanguage) {
            updateSearch({
                lang: currentLanguage
            });
        }
    }, [currentLanguage]);

    const handleAuthorize = () => {
        setAuthenticated(true);
    };

    const handleContextChange = (context: string) => {
        updateSearch({
            context
        });
    }

    const handlePageKeyChange = (page: string) => {
        updateSearch({
            page
        });
    }

    if (!magazineId) {
        return <div>No magazine selected</div>;
    }

    if (!isAuthenticated) {
        return (
            <SimpleAuthentication expectedToken={HUMAN_TOKEN} onAuthorized={handleAuthorize}/>
        )
    }

    return (
        <MagazineOutlet
            embedded={embedded}
            domain={domain}
            language={language}
            magazineId={magazineId}
            context={context}
            withMenu={withMenu}
            pageKey={pageKey}
            extraParams={extraParams}
            onContextChange={handleContextChange}
            onPageChange={handlePageKeyChange}
        />
    )
}